body {
    background-color: rgb(18, 18, 18); /* Dark background */
    color: #fff; /* Light text color */
    font-family: 'system-ui', sans-serif; /* Futuristic font */
    margin: 0;
    padding: 0;
}

#root{
	display: flex;
	justify-content: center;
	align-items: center;
}

#main{
	display: flex;
	/* border:1px solid #fff; */
	/* width: 70%; */
	width: 1280px;
	/* height: 500px; */
	flex-direction: row;
	margin: 25px;
	/* height: fit-content; */
	
}

#info-card{
	width: 40%; /* Fixed width */
	/* border: 1px solid red; */
	display: flex; /* Center the text inside the box */
	justify-content: left;
	/* align-items: center; */
	flex-direction: column;
	margin: 1px;
	margin-top: 20px;
}

#rest{
	width: 60%;
	height: 1000px; /* Fixed height */
	/* border: 1px solid blue; */
	display: flex; /* Center the text inside the box */
	/* justify-content: center; */
	/* align-items: center; */
	flex-direction: column;
	margin: 1px;
	
}

#avatar{
	width: 128px;
	height: 128px;
	margin: 5px;
	border-radius: 8px;
}

.info{
	/* border: 1px solid blue; */
	margin-left: 5px;
	margin-top:20px;
}

h1, h2, h3 {
	margin:0;
	font-family: system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
	--tw-text-opacity: 1;
	text-wrap: balance;
}

h1{
	font-size: 36px;
	line-height: 40px;
	font-weight: 700;
	color: rgba(255,255,255);
}

h2{
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: rgba(150, 150, 150);
}

h3{
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: rgba(150, 150, 150);
	margin-left: 5px;
}

.social-media-links {
	display: flex;
	justify-content: left; /* Align icons in the center */
	gap: 15px; /* Spacing between icons */
	margin-top: 10px; /* Optional: Add margin for spacing */
	margin-left: 10px;
  }
  
  .social-icon {
	color: rgb(227, 77, 10);
	font-size: 18px; /* Adjust icon size */
	transition: transform 0.2s, color 0.2s; /* Add hover effects */
  }
  
  .social-icon:hover {
	color: #ffffff; /* Highlight color on hover */
	transform: scale(1.2); /* Slightly enlarge icon on hover */
  }
  
  .social-icon:active {
	transform: scale(1.1); /* Smaller scale when clicked */
  }
  
.about{
	margin-left: 5px;
}
.aboutTitle{
	font-size: 30px;
	line-height: 36px;
	font-weight: 400;
	color: rgba(255,255,255);
	margin-top: 25px;
	margin-bottom: 10px;
	margin-left: 10px;
}

.aboutText{
	margin-bottom: 10px;
	margin-left: 10px;
	font-weight: 600;
}

.project-cards{
	/* border:5px solid yellow; */
}

.project-cards {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: left;
	margin-left: 10px;
	margin-top: 20px;
	margin-bottom: 10px;
}
  
.card {
	background-color: #161616;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 1rem;
	width: 300px;
	transition: transform 0.3s ease;
	border: 0.5px solid rgb(150, 150, 150);
}

.card:hover {
	transform: scale(1.02);
}

.card-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.folder-icon {
	color: #acacac;
	font-size: 1rem;
}

.github-icon {
	font-size: 1.5rem;
	transition: color 0.3s ease;
	color: white;
}

.github-icon:hover {
	color: #e34d0a;
}

.project-title {
	font-size: 16px;
	line-height: 24px;
	margin-left: 8px;
	flex: 1;
	text-align: left;
	color: white;
	font-family: system-ui;
	font-weight: 600;
}

.project-description {
	color: white;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 1rem;
	font-family: system-ui;
	font-weight: 600;
}

.project-details {
	list-style: disc;
	padding-left: 1.5rem;
	color: #969696;
	font-size: 14px;
	line-height: 20px;
	font-family: system-ui;
	font-weight: 600;
}

#skills-container{
	/* margin-top: 20px;
	margin-left: 10px; */
}

.skills-list {
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1.5rem;
}

.skills-item {
	display: flex;
	align-items: center;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease;
}

.skills-item:hover {
	transform: translateY(-5px);
}

.skills-icon {
	font-size: 1.3rem;
	color: rgb(227, 77, 10);
	margin-right: 0.8rem;
}

.skills-name {
	font-size: 1rem;
	list-style: disc;
	color: #969696;
	font-size: 14px;
	line-height: 20px;
	font-family: system-ui;
	font-weight: 500;
	font-family: system-ui,sans-serif;
}

/* .education-card{
	margin-bottom: 100px;
} */
.uni-card{
	display: flex;
	margin-left:10px;
	margin-top:20px;
}

.grad-icon{
	color:white;
	margin-top:5px;
}

.uni{
	margin-left:10px;
}

.footer-container {
	position: fixed; /* Keeps it at the bottom of the viewport */
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #E34D0A;
	color: #fff;
	text-align: center;
	box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
  }
  
  .footer-content {
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
.resume{
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	color: white;
	font-family: system-ui;
	font-weight: 600;
}

.empty{
	height: 100px;
}

.resume a {
	color: #ffffff; /* White color */
	text-decoration: none; /* Removes underline */
	font-weight: bold;
  }
  
  .resume a:hover {
	text-decoration: underline; /* Adds underline on hover */
  }
  
  @media (max-width: 1000px) {
	#main {
	  flex-direction: column; /* Stack divs vertically on small screens */
	  width: 90%;
	}
	#rest{
		width: 100%;
	}
	#info-card{
		width: 100%;
	}
	.footer-container{
		height: 30px;
	}
  }

  .contact-form {
	background-color: #161616;
	border-radius: 8px;
	padding: 20px;
	margin: 20px auto;
	width: 100%;
	max-width: 400px;
	color: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .contact-form-title {
	font-size: 24px;
	margin-bottom: 10px;
	text-align: center;
	color: #E34D0A;
  }
  
  .contact-form label {
	display: block;
	font-size: 14px;
	margin-bottom: 5px;
	color: #ccc;
  }
  
  .contact-form input,
  .contact-form textarea {
	width: 100%;
	padding: 10px;
	margin-bottom: 15px;
	border: 1px solid #444;
	border-radius: 4px;
	background-color: #222;
	color: white;
	font-size: 14px;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
	border-color: #E34D0A;
	outline: none;
  }
  
  .submit-button {
	background-color: #E34D0A;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 14px;
	width: 100%;
  }
  
  .submit-button:hover {
	background-color: #ff5c1a;
  }
  